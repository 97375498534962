import React, { useRef } from "react"
import Banner from "../components/Banner"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import Button from "../components/Button"
import {
  Body,
  Header1,
  Header1Accent,
  Header2,
  Header3,
  Header5,
} from "../components/Typography"
import SectionHeader from "../components/SectionHeader"
import Section from "../components/Section"
import { SolutionsRow, Solution } from "../components/solutionsRow"
import HelpRow from "../components/HelpRow"
import { FiActivity, FiCompass, FiTarget, FiTool } from "react-icons/fi"
import opengraph from "../images/opengraph.png"

function IndexPage({ data }) {
  const banner = useRef(null)
  return (
    <Layout pageTitle="Home" image={opengraph}>
      <Banner
        text={[
          <Header1>
            Superior engagement drives{" "}
            <Header1Accent>superior giving</Header1Accent>
          </Header1>,
          <Header3>AI with a Human Touch&trade;</Header3>,
        ]}
        buttons={[
          <Button primary url="/demo">
            Request Demo
          </Button>,
          <Button secondary url="#where-we-help">
            See Solutions
          </Button>,
        ]}
        ref={banner}
        image={data.image}
      />
      <Section>
        <SectionHeader>What We Do</SectionHeader>
        <Header2 style={{ margin: "32px 0px 48px 0px" }}>
          Your next generation advancement solution
        </Header2>
        <SolutionsRow>
          <Solution>
            <div>
              <FiTarget />
            </div>
            <Header5>Donor-Centric Engagement</Header5>
            <Body>
              Our multi-channel alumni engagement solution is automatically
              personalized for each alum and designed to strengthen emotional
              connection and alumni identity, both proven drivers of giving.
            </Body>
          </Solution>
          <Solution>
            <div>
              <FiTool />
            </div>
            <Header5>Pipeline Building</Header5>
            <Body>
              Your gift officers' time is too valuable to build portfolios using
              outdated scoring methods. Leverage AI to more accurately qualify
              your prospects.
            </Body>
          </Solution>
          <Solution>
            <div>
              <FiCompass />
            </div>
            <Header5>Large-scale Discovery for Cultivation</Header5>
            <Body>
              Turn prospects into donors by automatically uncovering actionable
              insights about your current and future prospects.
            </Body>
          </Solution>
          <Solution>
            <div>
              <FiActivity />
            </div>
            <Header5>Impact Analysis</Header5>
            <Body>
              Analyze how each department impacts your university’s
              philanthropic goals. Easily uncover the specific factors that are
              enabling you to reach your fundraising objectives.
            </Body>
          </Solution>
        </SolutionsRow>
      </Section>
      <Section id="where-we-help">
        <SectionHeader>Where we can Help</SectionHeader>
        <HelpRow />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    image: allFile(
      filter: {
        relativePath: { eq: "index/pang-yuhao-_kd5cxwZOK4-unsplash.jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
`

export default IndexPage
