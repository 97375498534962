import * as React from "react"
import { Link } from "gatsby"
import { FiArrowRight } from "react-icons/fi"
import styled from "styled-components"
import { Body, Header3, Header5 } from "./Typography"

const HelpRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 32px;
  gap: 32px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const HelpCard = styled.div`
  display: inline-grid;
  grid-auto-flow: row;
  gap: 16px;
  padding: 32px;
  background: var(--base000);
  border: 1px solid var(--base070);
  border-radius: 2px;
  position: relative;
  justify-content: center;
  align-content: start;
  color: var(--base070);

  :after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.1;
    pointer-events: none;
  }

  :hover {
    h3,
    p {
      color: var(--base100);
    }
    h3 svg {
      color: var(--primary100);
    }
  }

  :hover:after {
    box-shadow: 0px 0px 40px var(--base100);
    opacity: 0.25;
  }

  span {
    white-space: nowrap;
  }
`

export const HelpCardBullet = styled(Link)`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  justify-content: start;
  align-items: start;
  font-size: 24px;
  position: relative;
  z-index: 10;

  svg {
    margin-top: 4px;
  }

  :hover {
    color: var(--base100);
    svg {
      color: var(--primary100);
    }
  }
`

const AbsoluteLink = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`

const HelpRow = () => {
  return (
    <HelpRowWrapper>
      <HelpCard>
        <AbsoluteLink to="/development" />
        <Header3>
          <span>
            Development <FiArrowRight />
          </span>
        </Header3>
        <Body>
          Our AI engine and deep donor insights are a force multiplier for
          development professionals. Maximize your university's fundraising
          potential by reducing workload and increasing return on effort.
        </Body>
        <HelpCardBullet to="/development#qualification">
          <FiArrowRight />
          <Header5>Qualification</Header5>
        </HelpCardBullet>
        <HelpCardBullet to="/development#cultivation">
          <FiArrowRight />
          <Header5>Cultivation</Header5>
        </HelpCardBullet>
        <HelpCardBullet to="/development#impact-analysis">
          <FiArrowRight />
          <Header5>Impact Analysis</Header5>
        </HelpCardBullet>
        <HelpCardBullet to="/development#culture">
          <FiArrowRight />
          <Header5>Culture of Philanthropy</Header5>
        </HelpCardBullet>
      </HelpCard>
      <HelpCard>
        <AbsoluteLink to="/alumni-relations" />
        <Header3>
          Alumni{" "}
          <span>
            Relations <FiArrowRight />
          </span>
        </Header3>
        <Body>
          Show each alum how much they matter by giving them the individualized
          engagement experience they deserve. Strengthen their emotional
          connection by providing lifelong value.
        </Body>
        <HelpCardBullet to="/alumni-relations#engagement">
          <FiArrowRight />
          <Header5>Engagement</Header5>
        </HelpCardBullet>
        <HelpCardBullet to="/alumni-relations#event-management">
          <FiArrowRight />
          <Header5>Event Management</Header5>
        </HelpCardBullet>
      </HelpCard>
      <HelpCard>
        <AbsoluteLink to="/artificial-intelligence" />
        <Header3>
          AI with a Human{" "}
          <span>
            Touch&trade; <FiArrowRight />
          </span>
        </Header3>
        <Body>
          Leverage AI in your advancement operations to increase predictability,
          minimize cost, and maximize giving. We use technology to help people,
          not to replace them
        </Body>
        <HelpCardBullet to="/artificial-intelligence#future">
          <FiArrowRight />
          <Header5>AI in Advancement</Header5>
        </HelpCardBullet>
        <HelpCardBullet to="/artificial-intelligence#predictive-analytics">
          <FiArrowRight />
          <Header5>Predictive Analytics</Header5>
        </HelpCardBullet>
        <HelpCardBullet to="/artificial-intelligence#process">
          <FiArrowRight />
          <Header5>CueBack's AI Process</Header5>
        </HelpCardBullet>
      </HelpCard>
    </HelpRowWrapper>
  )
}

export default HelpRow
